import loadable from '@loadable/component';

export const LoadableIcons = {
  Arrow: loadable(
    () => import(/* webpackPrefetch: true */ '../Atoms/Icons/ArrowIcon')
  ),
  Cart: loadable(
    () => import(/* webpackPrefetch: true */ '../Atoms/Icons/CartIcon')
  ),
  Chevron: loadable(
    () => import(/* webpackPrefetch: true */ '../Atoms/Icons/ChevronIcon')
  ),
  CirclePlus: loadable(
    () => import(/* webpackPrefetch: true */ '../Atoms/Icons/CirclePlusIcon')
  ),
  Close: loadable(
    () => import(/* webpackPrefetch: true */ '../Atoms/Icons/CloseIcon')
  ),
  Contact: loadable(
    () => import(/* webpackPrefetch: true */ '../Atoms/Icons/ContactIcon')
  ),
  Document: loadable(
    () => import(/* webpackPrefetch: true */ '../Atoms/Icons/DocumentIcon')
  ),
  Download: loadable(
    () => import(/* webpackPrefetch: true */ '../Atoms/Icons/DownloadIcon')
  ),
  Globe: loadable(
    () => import(/* webpackPrefetch: true */ '../Atoms/Icons/GlobeIcon')
  ),
  Heart: loadable(
    () => import(/* webpackPrefetch: true */ '../Atoms/Icons/HeartIcon')
  ),
  Home: loadable(
    () => import(/* webpackPrefetch: true */ '../Atoms/Icons/HomeIcon')
  ),
  Logo: loadable(
    () => import(/* webpackPrefetch: true */ '../Atoms/Icons/Logo')
  ),
  Menu: loadable(
    () => import(/* webpackPrefetch: true */ '../Atoms/Icons/MenuIcon')
  ),
  Profile: loadable(
    () => import(/* webpackPrefetch: true */ '../Atoms/Icons/ProfileIcon')
  ),
  ProfileFilled: loadable(
    () => import(/* webpackPrefetch: true */ '../Atoms/Icons/ProfileIconFilled')
  ),
  Plus: loadable(
    () => import(/* webpackPrefetch: true */ '../Atoms/Icons/PlusIcon')
  ),
  Search: loadable(
    () => import(/* webpackPrefetch: true */ '../Atoms/Icons/SearchIcon')
  ),
  Sorting: loadable(
    () => import(/* webpackPrefetch: true */ '../Atoms/Icons/SortingIcon')
  ),
  Theme: loadable(
    () => import(/* webpackPrefetch: true */ '../Atoms/Icons/ThemeIcon')
  ),
  Trash: loadable(
    () => import(/* webpackPrefetch: true */ '../Atoms/Icons/TrashIcon')
  ),
  Checkmark: loadable(
    () => import(/* webpackPrefetch: true */ '../Atoms/Icons/CheckmarkIcon')
  ),
  Edit: loadable(
    () => import(/* webpackPrefetch: true */ '../Atoms/Icons/EditIcon')
  ),
  Cooking: loadable(
    () => import(/* webpackPrefetch: true */ '../Atoms/Icons/CookingIcon')
  ),
  Serving: loadable(
    () => import(/* webpackPrefetch: true */ '../Atoms/Icons/ServingIcon')
  ),
  TableSetting: loadable(
    () => import(/* webpackPrefetch: true */ '../Atoms/Icons/TableSettingIcon')
  ),
  Furniture: loadable(
    () => import(/* webpackPrefetch: true */ '../Atoms/Icons/FurnitureIcon')
  ),
};

const KexIconLoader = (icon: string) => {
  return (LoadableIcons as any)[icon];
};

export default KexIconLoader;
